<template>
  <li class="add-referral-template">
    <div>
      <ion-icon name="add-outline"></ion-icon>
    </div>
    <h4>Post A New Opportunity</h4>
  </li>
</template>

<script>
export default {};
</script>

<style>
.add-referral-template {
  margin: 3vh;
  flex: 0 1 28%;
  border: 0.5vh dashed rgb(165, 164, 164);
  color: rgb(165, 164, 164);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3vh;
  height: 30vh;
}

.add-referral-template div {
  font-size: 600%;
}

.add-referral-template h4 {
  text-align: center;
}

.add-referral-template:hover {
  color: grey;
  border-color: grey;
  cursor: pointer;
}
</style>
